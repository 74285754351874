import * as discos from './discos'
import formatters from './formatters'
import * as lambda from './lambda.server'

let ssrConstants: Record<string, any>
if (typeof window === 'undefined') {
  /**
   * "require" fails during test runs
   */
  try {
    ssrConstants = {
      ...(require('./constants.server').default || {}),
      ...(require('./constants.client').default || {}),
    }
  } catch {
    ssrConstants = { lambda }
  }
} else {
  try {
    ssrConstants = require('./constants.client') || {}
  } catch {
    ssrConstants = {}
  }
}

export const support = {
  whatsappLink: `https://lmn.page/wpp`,
  noContactFoundMessage:
    'Oi, estou com problemas para entrar na Lemon. Parece que falta um contato no meu cadastro. Podem me ajudar?',
  noContactAccess:
    'Oi, estou com problemas para entrar na Lemon. Não tenho acesso a nenhum dos contatos mostrados. Podem me ajudar?',
  noDocumentMessage:
    'Oi, estou com problemas para entrar na Lemon. Parece que meu CPF não está cadastrado. Podem me ajudar?',
  invoiceHelp: `Oi, estou com problemas com minha conta Lemon. Podem me ajudar?`,
  legalHelp: `Oi, estou com problemas. Podem me ajudar?`,
  noLemonBillEmitted: `Oi, estou com problemas. Não recebi minha conta Lemon. Podem me ajudar?`,
}

const constants: Record<string, any> = {
  ...ssrConstants,
  discos,
  formatters,
  support,
}

export const externalHelpLinks = {
  'cpf-screen': 'https://lmn.page/BvO2V',
  'contact-methods-screen': 'https://lmn.page/M54Be',
  'otp-code-screen': 'https://lmn.page/pEY35',
}

export const configureNotificationParam = 'configure=true'

export type InvoiceStatus = 
  | 'paid'
  | 'waitingPayment'
  | 'refunded'
  | 'cancelled'
  | 'overdue'
  | 'waitingInvoice'
  | 'underpaid'
  | 'overpaid'

export default constants
